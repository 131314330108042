<template>
  <S2SForm title="Search An Order">
    <S2SCard>
      <v-flex xs12 md6>
        <v-text-field
          v-model="orderId"
          name="orderid"
          label="Order Id"
        ></v-text-field>
      </v-flex>
      <v-flex class="text-xs-left" xs12 mb-2>
        <v-btn color="primary" @click="onSearch()">Search</v-btn>
      </v-flex>
      <v-flex xs12 md6>
        <v-card v-if="selectedOrder && selectedOrder.id">
          <v-card-title primary-title
            >Found Order: {{ selectedOrder.id }}</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text height="auto">
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <div class="text-xs-left ml-3 mt-2">
                  <div>
                    Order Placed:
                    <span style="font-weight: 500">{{
                      formatDate(selectedOrder.dateCreated)
                    }}</span>
                  </div>
                  <div>
                    Order Total:
                    <span style="font-weight: 500">{{
                      formatCurrency(selectedOrder.price)
                    }}</span>
                  </div>
                  <div>
                    Payment Status:
                    <span style="font-weight: 500">{{
                      selectedOrder.paymentStatus
                    }}</span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="accent"
              :block="$vuetify.breakpoint.xsOnly"
              @click="onDetailedView"
              >Detailed View</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </S2SCard>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils";

  export default Vue.extend({
    name: "Orders",

    data: function () {
      return {
        orderId: "",
      };
    },

    computed: {
      selectedOrder: function () {
        return this.$store.state.orders.selectedOrder;
      },
    },

    methods: {
      onSearch() {
        this.$store.dispatch("orders/fetchOrder", this.orderId);
      },
      onDetailedView() {
        this.$router.push({
          name: "view-order",
          params: { orderId: this.orderId },
        });
      },
      formatDate(text) {
        return Utils.formatText(text, Utils.TextType.DATE_YYYY_MM_DD);
      },
      formatCurrency(text) {
        return Utils.formatText(text, Utils.TextType.CURRENCY);
      },
    },
  });
</script>

<style>
  .dialog {
    background-color: secondary;
  }

  .grid-row {
    cursor: pointer;
  }
</style>
